<template>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page">Документы</h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">
            <h2>Типовой договор о проведении ТО</h2>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/Образец%20Типового%20Договора.doc">
                  <img src="/files/dog.PNG" alt="Типовой договор о проведении ТО" title="Типовой договор о проведении ТО">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/Образец%20Типового%20Договора.doc">Типовой договор о проведении ТО</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/Образец%20Типового%20Договора.doc">Скачать: DOC</a>
                  </div>
                </div>

              </div>
            </div>
            <h2>Документация для пользователей</h2>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/Режим%20работы.pdf">
                    <img src="/files/режим.PNG" alt="РЕЖИМ РАБОТЫ" title="РЕЖИМ РАБОТЫ">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/Режим%20работы.pdf">РЕЖИМ РАБОТЫ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/Режим%20работы.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
            </div>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/Перечень_документов,_необходимых_для_проведения_ТО.pdf">
                    <img src="/files/Список.PNG" alt="Перечень документов для проведения ТО" title="Перечень документов для проведения ТО">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/Перечень_документов,_необходимых_для_проведения_ТО.pdf">Перечень документов для проведения ТО</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/Перечень_документов,_необходимых_для_проведения_ТО.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
            </div>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/реквизиты%20Техавтотест.doc">
                    <img src="/files/карточка.PNG" alt="ИНФОРМАЦИОННАЯ КАРТОЧКА" title="ИНФОРМАЦИОННАЯ КАРТОЧКА">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/реквизиты%20Техавтотест.doc">ИНФОРМАЦИОННАЯ КАРТОЧКА</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/реквизиты%20Техавтотест.doc">Скачать: DOC</a>
                  </div>
                </div>

              </div>
            </div>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/ScannerPro%20doc.pdf">
                    <img src="/files/m2m3.PNG" alt="ГРАФИК ПРИЁМА М2,М3" title="ГРАФИК ПРИЁМА М2,М3">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/ScannerPro%20doc.pdf">ГРАФИК ПРИЁМА М2,М3</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/ScannerPro%20doc.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
            </div>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/Федеральный_закон_ПОВТОРНОЕ_ПРОХОЖДЕНИЕ_ТО.PDF">
                    <img src="/files/170-ФЗ.PNG" alt="Проведение повторного технического осмотра" title="Проведение повторного технического осмотра">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/Федеральный_закон_ПОВТОРНОЕ_ПРОХОЖДЕНИЕ_ТО.pdf">Федеральный закон  от 01.07.2011 N 170-ФЗ ст 18 (ред. от 01.04.2020) Проведение повторного технического осмотра</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/Федеральный_закон_ПОВТОРНОЕ_ПРОХОЖДЕНИЕ_ТО.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
            </div>
            <h2>
              Учредительные документы
            </h2>
            <div class="row docs" >
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/upload/iblock/ed8/ed88abb4d3ff38223494846b2c94922b.pdf"></a>
                  <img src="/files/upload/iblock/363/363763a633c5d49b5330989a02b9429a.jpg" alt="ПРИКАЗ №1"
                       title="ПРИКАЗ №1">
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/upload/iblock/ed8/ed88abb4d3ff38223494846b2c94922b.pdf">ПРИКАЗ
                        №1</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/upload/iblock/ed8/ed88abb4d3ff38223494846b2c94922b.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
              <div class="item col col-md-4" >
                <div class="image">
                  <a href="/files/upload/iblock/87b/87b7377c00e0216d73106ad81215e432.pdf">
                  <img src="/files/upload/iblock/2ce/2cea9cbcd61a62b607cfd68d89ed85f8.jpg" alt="УСТАВ" title="УСТАВ">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/upload/iblock/87b/87b7377c00e0216d73106ad81215e432.pdf">УСТАВ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/upload/iblock/87b/87b7377c00e0216d73106ad81215e432.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
              <div class="item col col-md-4" >
                <div class="image">
                  <a href="/files/upload/iblock/bfc/bfc86dc1375110679a5f5541b8cd2a5f.pdf">
                  <img src="/files/upload/iblock/6fa/6fa07e67d3571c2b7d736bbae5cc3f57.jpg" alt="УВЕДОМЛЕНИЕ"
                       title="УВЕДОМЛЕНИЕ">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/upload/iblock/bfc/bfc86dc1375110679a5f5541b8cd2a5f.pdf">УВЕДОМЛЕНИЕ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/upload/iblock/bfc/bfc86dc1375110679a5f5541b8cd2a5f.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
              <div class="item col col-md-4" >
                <div class="image">
                  <a href="/files/upload/iblock/312/312e79c8157af3c95880a573cea47392.pdf">
                  <img src="/files/upload/iblock/245/245c9f967448c82cef24311c90a314e3.jpg" alt="ПОСТАНОВКА В НАЛОГОВОЙ"
                       title="ПОСТАНОВКА В НАЛОГОВОЙ">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/upload/iblock/312/312e79c8157af3c95880a573cea47392.pdf">ПОСТАНОВКА
                        В НАЛОГОВОЙ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/upload/iblock/312/312e79c8157af3c95880a573cea47392.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
              <div class="item col col-md-4" >
                <div class="image">
                  <a href="/files/upload/iblock/87f/87f0ee6ff306f91136a42ad7f805e533.pdf"></a>
                  <img src="/files/upload/iblock/f00/f0091e66733c8b23715582f6b086fdcc.jpg" alt="ЕГРЮЛ" title="ЕГРЮЛ">

                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/upload/iblock/87f/87f0ee6ff306f91136a42ad7f805e533.pdf">ЕГРЮЛ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/upload/iblock/87f/87f0ee6ff306f91136a42ad7f805e533.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
            </div>

            <h2>Лицензии и сертификаты</h2>
            <div class="row docs">
              <div class="item col col-md-4">
                <div class="image">
                  <a href="/files/upload/iblock/4b5/4b5e9c6849ae6da842195016e691df6f.pdf">
                  <img src="/files/upload/iblock/10a/10a635137f13452cd9f56418b3e2aae0.jpg" alt="АТТЕСТАТ" title="АТТЕСТАТ">
                  </a>
                </div>
                <div class="data">
                  <div class="info smaller">
                    <div class="name">
                      <a class="aprimary" href="/files/upload/iblock/4b5/4b5e9c6849ae6da842195016e691df6f.pdf">АТТЕСТАТ</a>
                    </div>
                  </div>
                  <div class="dl">
                    <a href="/files/upload/iblock/4b5/4b5e9c6849ae6da842195016e691df6f.pdf">Скачать: PDF</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Docs"
}
</script>

<style scoped>

</style>
